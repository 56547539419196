import { alias, serializable } from 'serializr';

export class PendingInformationUpdate {
  @serializable(alias('required'))
  private _required: boolean;

  @serializable(alias('editAllowed'))
  private _editAllowed: boolean;

  get required(): boolean {
    return this._required;
  }

  get editAllowed(): boolean {
    return this._editAllowed;
  }
}
